import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { MainRoutes } from './components/MainRoutes';

// Hot module replacement
if (module && module.hot) {
  module.hot.accept()
}

ReactDOM.render(<MainRoutes/>, document.getElementById('root'));
