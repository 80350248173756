import * as React from "react";

import { DropDown, DropdownStyles } from "./dropdown";
import { Layout } from "./Layout";
import { ShoeWall } from "./shoewall";

import { allUiConfigs } from "../configs/ui_config";
import { sendAnalyticsEvent } from "../tools/analytics";
import { Auth } from "../tools/auth";
import { createShoeWall } from "../tools/gateway";
import {
  checkStatus,
  isValidEmail,
  findPosIDFromStoreName,
  findStoreNameFromPosID,
  getTenantFromQueryOrDefault,
} from "../tools/helpers";

import * as crypto from "crypto-js";

const CACHE_ENABLED = false;

const hashEmail = (email: string) => {
  return crypto.SHA256(email).toString();
};

export const Home: React.FC<{
  auth: Auth;
}> = ({ auth }) => {
  const tenant = getTenantFromQueryOrDefault();
  const uiConfig = allUiConfigs[tenant];
  sessionStorage.setItem(`tenant`, tenant);

  const [shoeData, setShoeData] = React.useState([]);
  const [resourceId, setResourceId] = React.useState(null);
  const [fitVis, setFitVis] = React.useState({});
  const [userId, setUserId] = React.useState("");
  const [myVlink, setMyVlink] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [shoeCategory, setShoeCategory] = React.useState<any>("");
  const [activeStoreName, setActiveStoreName] = React.useState<any>("");
  const [storeNameList, setStoreNameList] = React.useState<any>([]);

  const images = true;
  const fitZones = true;
  const count = 20;
  const userIdParam =
    uiConfig.user_id_type === "partner_id"
      ? userId
      : hashEmail(userId.trim().toLowerCase());

  React.useEffect(() => {
    if (userId) {
      sessionStorage.setItem(`${userId}_fitVis`, JSON.stringify(fitVis));
    }
  }, [fitVis]);

  React.useEffect(() => {
    if (!uiConfig.enable_whole_catalog_search) {
      const storeListCached = sessionStorage.getItem(`${tenant}_storeList`);
      const firstStoreNameInList =
        storeListCached &&
        JSON.parse(storeListCached) &&
        JSON.parse(storeListCached)[0]
          ? JSON.parse(storeListCached)[0]["org_name"]
          : "";
      setActiveStoreName(firstStoreNameInList);
    }
  }, [storeNameList]);

  // fetch the last visited store name once when userId changed -- done only once
  React.useEffect(() => {
    if (userId && uiConfig.enable_store_filter) {
      const storeListCached = sessionStorage.getItem(`${tenant}_storeList`);
      const preselectedStoreNameCached = sessionStorage.getItem(
        `${tenant}_${userId}_preselectedStoreName`
      );
      if (!preselectedStoreNameCached) {
        let preselectedStoreId = storeListCached
          ? findPosIDFromStoreName(JSON.parse(storeListCached), activeStoreName)
          : "";
        createShoeWall(
          tenant,
          userIdParam,
          fitZones,
          images,
          count,
          preselectedStoreId,
          ""
        )
          .then(checkStatus)
          .then((res) => res.json())
          .then((res) => res.data)
          .then((data) => {
            const lastVisitedStoreName =
              storeListCached && data.store_pos_id.length > 0
                ? findStoreNameFromPosID(
                    JSON.parse(storeListCached),
                    data.store_pos_id[0]
                  )
                : "";
            setActiveStoreName(lastVisitedStoreName);
            sessionStorage.setItem(
              `${tenant}_${userId}_preselectedStoreName`,
              lastVisitedStoreName
            );
          });
      } else {
        console.log("your preselected store ", preselectedStoreNameCached);
        setActiveStoreName(preselectedStoreNameCached);
      }
    }
  }, [userId, tenant]);

  React.useEffect(() => {
    setError("");
    if (!userId) {
      return;
    }
    // Only validate userId when email is the input.
    if (uiConfig.user_id_type === "email_hash" && !isValidEmail(userId)) {
      const msg = "Invalid email";
      setError({
        reason: msg,
      });
      // send mixpanel event
      sendAnalyticsEvent("User saw error", {
        active_user_id: userId,
        error_message: msg,
        tenant,
      });
      return;
    }
    const shoeDataCached = sessionStorage.getItem(`${userId}_shoeData`);
    const resourceIdCached = sessionStorage.getItem(`${userId}_resourceId`);
    const fitVisCached = sessionStorage.getItem(`${userId}_fitVis`);
    const myVlinkCached = sessionStorage.getItem(`${userId}_myVlink`);
    if (
      CACHE_ENABLED &&
      shoeDataCached &&
      resourceIdCached &&
      fitVisCached &&
      myVlinkCached
    ) {
      setShoeData(JSON.parse(shoeDataCached));
      setResourceId(JSON.parse(resourceIdCached));
      setFitVis(JSON.parse(fitVisCached));
      setMyVlink(myVlinkCached);

      // send mixpanel event if shoewall was cached before
      sendAnalyticsEvent("Fetched shoewall", {
        active_user_id: userId,
        myv_link: myVlinkCached,
        resource_id: resourceIdCached,
        type: "used cached shoewall",
        tenant,
      });
    } else {
      setLoading(true);
      setShoeData([]);
      setResourceId(null);
      setFitVis({});
      setMyVlink("");
    }
    const storeListCached = sessionStorage.getItem(`${tenant}_storeList`);
    const posID = storeListCached
      ? findPosIDFromStoreName(JSON.parse(storeListCached), activeStoreName)
      : "";
    createShoeWall(
      tenant,
      userIdParam,
      fitZones,
      images,
      count,
      posID,
      shoeCategory === "all" ? "" : shoeCategory
    )
      .then(checkStatus)
      .then((res) => res.json())
      .then((res) => res.data)
      .then((data) => {
        // send mixpanel event if shoewall is created
        sendAnalyticsEvent("Fetched shoewall", {
          active_user_id: userId,
          myv_link: data.myv_link,
          resource_id: data.id,
          tenant,
          type: "created new shoewall",
        });

        sessionStorage.setItem(
          `${userId}_shoeData`,
          JSON.stringify(data.shoes)
        );
        sessionStorage.setItem(`${userId}_resourceId`, JSON.stringify(data.id));
        sessionStorage.setItem(`${userId}_myVlink`, data.myv_link);
        setShoeData(data.shoes);
        setResourceId(data.id);
        setMyVlink(data.myv_link);
        const shoeWallId = data.id;
        data.shoes.map((s: any) => {
              if (shoeWallId === data.id) {
                setFitVis((prev) => ({
                  ...prev,
                  [s.order]: s.fit_viz_url,
                }));
                sessionStorage.setItem(
                  `${userId}_fitVis`,
                  JSON.stringify(fitVis)
                );
              }
            })
        setResourceId(data.id);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        // send mixpanel event
        sendAnalyticsEvent("User saw error", {
          active_user_id: userId,
          error_message: "Cannot fetch shoewall",
          tenant,
        });
      });
  }, [userId, shoeCategory, activeStoreName]);

  return (
    <Layout
      error={error}
      auth={auth}
      tenant={tenant}
      onFetchShoes={setUserId}
      onFetchStoreNames={setStoreNameList}
      uiConfig={uiConfig}
    >
      <div className={DropdownStyles.container}>
        <div className="dropdown-section">
          <span className={DropdownStyles.label}>Choose category:</span>
          <DropDown
            activeItem={shoeCategory || "all"}
            options={uiConfig.category_list}
            itemClickEvent={(cat) => {
              if (loading) {
                return;
              }
              // send mixpanel event
              sendAnalyticsEvent("Interacted with shoewall", {
                active_user_id: userId,
                widget_name: "category dropdown",
                tenant,
              });
              setLoading(!!userId);
              setShoeData([]);
              setShoeCategory(cat);
            }}
          />
        </div>
        {
          // Don't show store picker for tenant FF & TAF.
          // Volumental tenant doesn't have stores info attached.
          uiConfig.enable_store_filter && (
            <div className="dropdown-section">
              <span className={DropdownStyles.label}>Choose store:</span>
              <DropDown
                activeItem={activeStoreName || "all"}
                options={storeNameList}
                itemClickEvent={(name) => {
                  if (loading) {
                    return;
                  }
                  // send mixpanel event
                  sendAnalyticsEvent("Interacted with shoewall", {
                    active_user_id: userId,
                    widget_name: "store dropdown",
                    tenant,
                  });
                  setLoading(!!userId);
                  setShoeData([]);
                  setActiveStoreName(name);
                }}
              />
            </div>
          )
        }
      </div>
      <ShoeWall
        loading={loading}
        shoeData={shoeData}
        resourceId={resourceId}
        fitVis={fitVis}
        myVlink={myVlink}
        tenant={tenant}
        visitedStore={activeStoreName}
        onClickShoewall={(widgetName) => {
          // send mixpanel event
          sendAnalyticsEvent("Interacted with shoewall", {
            active_user_id: userId,
            widget_name: widgetName,
            tenant,
          });
        }}
      />
    </Layout>
  );
};
