import * as _ from 'lodash';
import { css } from 'emotion';
import * as React from 'react';

export const DropdownStyles = {
  container: css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px;
    flex-wrap: wrap;
  `,
  label: css`
    padding-right: 10px;
  `,
  root: css`
    position: relative;
    width: 300px;
    margin: 10px 0 0 0;
    justify-content: center;
    line-height: 40px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.25);
    .drop-down-current {
      border: 1px solid #131732;
      border-radius: 2px;
      cursor: pointer;
    }
    .drop-down-label {
      text-transform: capitalize;
      text-align: center;
    }
  `,
  menu: css`
    position: absolute;
    left: 0;
    top: 45px;
    z-index: 25;
    width: 100%;
    list-style-type: none;
    max-height: 200px;
    cursor: pointer;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-scrollbar {
      display: none;
    }
    box-shadow: 0 1px 13px 1px rgba(0, 0, 0, 0.1);
    li {
      position: relative;
      width: 100%;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      border-bottom: '1px solid rgba(0, 0, 0, 0.20)';
      overflow: hidden;
      &:last-child {
        border: none;
      }
      font-size: 14px;
      text-align: left;
      text-transform: capitalize;
      padding-left: 25px;
      margin: 0;
      &:hover {
        background-color: rgba(19, 23, 50, 0.30);
      }
    }
  `, 
};

export class DropDownMenu extends React.Component<{
  activeDefault: string;
  options: string[];
  onOptionClick: (category: string) => void;
  onBackgroundClick: () => void;
}, {}> {
  dropDownMenuRef = React.createRef<HTMLUListElement>();
  componentDidMount() {
    window.addEventListener('mousedown', this.checkOnBackgroundClick);
    window.addEventListener('touchstart', this.checkOnBackgroundClick);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.checkOnBackgroundClick);
    window.removeEventListener('touchstart', this.checkOnBackgroundClick);
  }

  checkOnBackgroundClick = (event: MouseEvent) => {
    if (this.dropDownMenuRef.current) {
      if (this.dropDownMenuRef.current.contains(event.target as Node)) {
        return;
      }
      const dropDownLabel = this.dropDownMenuRef.current.previousElementSibling;
      if (dropDownLabel && dropDownLabel.contains(event.target as Node)) { return; }
    }
    this.props.onBackgroundClick();
  }
  render() {
    return (
      <ul data-name='dropdown-menu' className={DropdownStyles.menu} ref={this.dropDownMenuRef}>
        {
          this.props.options.map((item, index) => (
            <li
              key={index}
              style={item === this.props.activeDefault ? {
                background: '#131732',
                color: 'white',
              } : {}}
              onClick={() => this.props.onOptionClick(item)}
            >
              {item}
            </li>
          ))
        }
      </ul>
    )
  }
};

interface Props {
  options: string[];
  activeItem: string;
  itemClickEvent: (item: string) => void;
}

interface State {
  showResults: boolean;
}

export class DropDown extends React.Component<Props, State> {
  state = { showResults: false };
  onClick = (): void => {
    if (this.props.options && this.props.options.length > 0) {
      this.setState({ showResults: !this.state.showResults });
    }
  }
  onBlurDropDown = _.debounce(() => this.setState({ showResults: false }), 50);
  onPickOption = (item: string): void => {
    console.log(item)
    this.setState({ showResults: false });
    if (item === this.props.activeItem) {
      return;
    }
    this.props.itemClickEvent(item);
  }

  render() {
    return (
      <div
        data-name="dropdown-root"
        className={`${this.state.showResults ? 'active' : ''} ${DropdownStyles.root}` }
        onClick={ this.onClick }
      >
        <div data-name="dropdown-current" className="drop-down-current">
          <div className="drop-down-label">
            { this.props.activeItem || null }
          </div>
        </div>
        { this.state.showResults ?
          <DropDownMenu
            activeDefault={ this.props.activeItem }
            options={ this.props.options }
            onOptionClick={ this.onPickOption }
            onBackgroundClick={ this.onBlurDropDown }
          />
        : null }
      </div>
    )
  }
}
