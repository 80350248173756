import { css, keyframes } from 'emotion';
import * as React from "react";

const opacityAnimationOnEntry = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Styles = {
  spinner: css`
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      margin: 60px auto;
      font-size: 4px;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ddd;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  `,
  productLink: css`
    background: #131732;
    color: white;
    text-decoration: none;
    padding: 6px;
    border-radius: 4px;
    font-size: 14px;
  `,
  shoeItemContainer: css`
    display: flex;
    flex-direction: row;
    height: 25vw;
    max-height: 200px;
    padding: 5px;
    background: white;
    align-items: center;
    animation: ${opacityAnimationOnEntry} 1s ease;
    font-size: 14px;
    border-top: 5px solid #ececec;
    overflow: hidden;
  `,
  shoeImg: css`
    flex: 0.8;
    img {
      max-width: 100%;
      height: auto;
    }
  `,
  shoeDetails: css`
    flex: 1;
    font-size: 14px;
    padding: 3px;
  `,
  fitVis: css`
    flex: 1;
    overflow: hidden;
  `,
  shoeAction: css`
    flex: 0.7;
    text-align: center;
  `,
  svg: css`
    svg {
      width: 100%;
      height: 100%;
    }
    animation: ${opacityAnimationOnEntry} 1s ease;
  `,
  availabilityText: css`
    padding: 5px;
    text-align: center;
  `,
};

export interface Availability {
  online: boolean;
  store: boolean;
}

export const ShoeItem: React.SFC<{
  availability:       Availability;
  productLink:        string;
  fitVisSVG:          string;
  shoeBrand:          string;
  shoeGender:         string;
  shoeImage:          string;
  shoeWidth:           string;
  shoeLength:          string;
  shoeStyle:          string;
  onClick:            (name: string) => void;
}> = ({
  availability,
  productLink,
  fitVisSVG,
  shoeBrand,
  shoeGender,
  shoeImage,
  shoeWidth,
  shoeLength,
  shoeStyle,
  onClick,
}) => {
  const availableOnline = availability.online ? 'Available online' : '';
  const availableInStore = availability.store ? 'Available in store' : '';
  return (
    <div data-name='shoe-item-root' className={Styles.shoeItemContainer}>
      <div data-name='shoe-image' className={Styles.shoeImg}>
        <img src={shoeImage} alt=""/>
      </div>
      <div data-name='shoe-details' className={Styles.shoeDetails}>
        <div
          style={{
            fontWeight: 'bold',
          }}
        >
          {shoeBrand}
        </div>
        <div>{shoeStyle}</div>
        <div>{shoeGender}</div>
        <div>{`Recommended Size: ${shoeLength} ${removeWidthPrefix(shoeWidth)}`}</div>
      </div>
      <div data-name='shoe-fit-vis' className={Styles.fitVis}>
        {
          fitVisSVG && (
            <div
              className={Styles.svg}
            >
              <img src={fitVisSVG}></img>
              </div>
          )
        }
        {
          !fitVisSVG && (
            <div
              className={Styles.spinner}
            >
              <div className="loader"></div>
            </div>
          )
        }
      </div>
      <div data-name='shoe-action' className={Styles.shoeAction}>
        {
          productLink && (
            <a
              className={Styles.productLink}
              href={productLink}
              target="_blank"
              onClick={() => onClick("Shoe item")}
            >
              View Shoe
            </a>
          )
        }
        <div data-name='shoe-store-available' className={Styles.availabilityText}>{availableInStore}</div>
        <div data-name='shoe-online-available' className={Styles.availabilityText}>{availableOnline}</div>
      </div>
    </div>
  )
};

const removeWidthPrefix = (widthLabel: string) =>
  widthLabel.startsWith('1') ? widthLabel.slice(1, widthLabel.length) : widthLabel;
