const DEFAULT_TENANT = "volumental";
const STORE_LINK_CONFIG = {
  volumental: "https://store.volumental.com/products",
  fleetfeet: "https://www.fleetfeet.com/products/sku",
  road_runner: "https://www.roadrunnersports.com/",
};

export interface StoreInfo {
  address: string;
  org_id: string;
  org_name: string;
  parent_org_id: string;
  phone_number: string;
  pos_id: string;
}

export const checkStatus = (response: Response): Response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(
    `status ${response.status} - ${response.statusText}`
  ) as any;
  error.reason = response.statusText;
  error.status = response.status;
  throw error;
};

export const isValidEmail = (email: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const getTenantFromQueryOrDefault = () => {
  const paramString = "tenant=";
  const params = window.location.search.substring(1).split("&");
  const tenantParam = params.find((p) => p.includes(paramString));
  return tenantParam ? tenantParam.replace(paramString, "") : DEFAULT_TENANT;
};

// TODO: storeName might be more than one
export const findStoreNameFromPosID = (
  storeList: StoreInfo[],
  posID: string
): string => {
  const storeItem = storeList.filter(
    (storeItem) => storeItem["pos_id"] === posID
  )[0];
  return storeItem ? storeItem["org_name"] : "";
};

// Notes: many orgs doesn't have pos ids so the shoewalls for those orgs might be the same as using a default tenant catalog.
export const findPosIDFromStoreName = (
  storeList: StoreInfo[],
  storeName: string
): string => {
  const storeItem = storeList.filter(
    (storeItem) => storeItem["org_name"] === storeName
  )[0];
  return storeItem ? storeItem["pos_id"] : "";
};

export const getAllStoreNames = (storeList: []): string[] => {
  return storeList.map((storeItem) => storeItem["org_name"]);
};

export const createStoreProductLink = (
  tenant: string,
  gender: string,
  brand: string,
  style: string,
  sku: string
) => {
  const baseLink = STORE_LINK_CONFIG[tenant];
  switch (tenant) {
    case "volumental":
      // match characters not letters or numbers, then remove non alphanumeric characters and adjacent dashes
      const handle = `${gender} ${brand} ${style}`
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/-$/, "")
        .replace(/^-/, "");
      return `${baseLink}/${handle}`;
    case "fleetfeet":
      return `${baseLink}/${sku}`;
    default:
      return "";
  }
};

export const preprocessStoreList = (storeList: StoreInfo[]): StoreInfo[] => {
  return storeList
    .filter((item) => item["pos_id"])
    .sort((a, b) => (a["org_name"] > b["org_name"] ? 1 : -1));
};
