import { css, keyframes } from 'emotion';
import * as React from 'react';
const myVlogo = require('../assets/images/myVlogo.jpg');
import { ShoeItem } from '../bit_components/shoe-item/shoeItem';
import { createStoreProductLink } from '../tools/helpers';

const OPACITY_DEPTH = 0.6;
const opacityAnimation = keyframes`
  from {
    opacity: ${OPACITY_DEPTH};
  }
  50% {
    opacity: 1
  }
  to {
    opacity: ${OPACITY_DEPTH};
  }
`

const opacityAnimationOnEntry = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Styles = {
  myVcontainer: css`
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto 20px;
    animation: ${opacityAnimationOnEntry} 1s ease;
  `,
  myVlogo: css`
    width: 100px;
  `,
  myVbutton: css`
    background: #131732;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
  `,
  store: css``,
  placeholder: css`
    animation: ${opacityAnimation} 2s ease infinite;
  `,
  shoeWallShoes: css``,
};


export const ShoeItemsPlaceholder: React.FC <{
  amount: number;
}> = ({ amount }) => {
  return (
    <div>
      <div
        className={Styles.placeholder}
        style={{
          height: 100,
          width: 100,
          background: 'rgba(19, 23, 50, 0.3)',
          borderRadius: '50%',
          textAlign: 'center',
          margin: '24px auto',
        }}
      >
      </div>
      {
        Array(amount).fill('').map((_, i) =>
          <div
            key={i}
            className={Styles.placeholder}
            style={{
              height: '25vw',
              background: 'rgba(19, 23, 50, 0.3)',
              margin: 20,
              borderRadius: 4,
              animationDelay: `${(i+1)*0.3}s`,
            }}
          >
          </div>
        )
      }
    </div>
  );
}

export const ShoeWall: React.FC<{
  shoeData: any;
  resourceId: any;
  fitVis: any;
  myVlink: string;
  loading: boolean;
  tenant: string;
  visitedStore: string;
  onClickShoewall: (widgetName: string) => void;
}> = ({ shoeData, fitVis, myVlink, loading, tenant, visitedStore, onClickShoewall }) => {
  if (!shoeData || !shoeData.length) {
    if (loading) {
      return <ShoeItemsPlaceholder amount={8} />
    } else {
      return null;
    }
  }
  return (
    <div>
      <a
        data-name='myv-link'
        href={myVlink}
        target="_blank"
        className={Styles.myVcontainer}
        onClick={() => onClickShoewall("my volumental link")}
      >
        <div data-name='myv-logo' className={Styles.myVlogo}>
          <img src={myVlogo} alt=""/>
        </div>
        <div data-name='myv-btn' className={Styles.myVbutton}>
          View 3D feet scan
        </div>
      </a>
      {/* <div className={Styles.store}>
        Your last visited store: {visitedStore}
      </div> */}
      <div data-name='shoe-items' className={Styles.shoeWallShoes}>
        {
          shoeData.map((shoe: any, i: number) => {
            var genderMsg = ""
            switch (shoe.gender) {
                case 'male':
                  genderMsg = "Men's Shoe";
                  break;
                case 'female':
                  genderMsg = "Women's Shoe"
                  break;
                default:
                  genderMsg = "Kids Shoe";
            }
            return <ShoeItem
              key={i}
              availability={{
                store: shoe.availability.store,
                online: shoe.availability.online,
              }}
              shoeWidth={shoe.width}
              shoeLength={shoe.length}
              shoeBrand={shoe.brand_name}
              shoeStyle={shoe.style_name}
              shoeGender={genderMsg}
              shoeImage={shoe.fullsize_image}
              fitVisSVG={shoe.fit_viz_url}
              productLink={
                createStoreProductLink(tenant, shoe.gender, shoe.brand_name, shoe.style_name, shoe.sku)}
              onClick={onClickShoewall}
            />;
          })
        }
      </div>
    </div>
  );
}
