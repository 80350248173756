import mixpanel, { Dict } from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';

const SLASK_TOKEN = 'e24d9df2874cdf166ce6181b266d266b';
const PRD_TOKEN = '9f7370ece9fb7a74ef333eacdb435dd4';

const initMixpanelAnalytics = (token: string) => {
  mixpanel.init(token, {
    api_host:'https://api.mixpanel.com',
  });
}

export const sendAnalyticsEvent = (eventName: string, analytics: Dict) => {
  return mixpanel.track(eventName, analytics);
}

export const sendAnalyticsReset = () => {
  if (process.env.NODE_ENV !== 'production') {
    initMixpanelAnalytics(SLASK_TOKEN); 
  } else {
    initMixpanelAnalytics(PRD_TOKEN);
    Sentry.init({
      dsn: 'https://429f10b4daac41a591509ef1cf453672@o47002.ingest.sentry.io/5416502',
    });
  }
  mixpanel.reset();
}
