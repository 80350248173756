import { css } from "emotion";
import * as React from "react";

import { UiConfig } from "../configs/ui_config";
import { sendAnalyticsEvent } from "../tools/analytics";
import { Auth } from "../tools/auth";
import { getStoreList } from "../tools/gateway";
import {
  checkStatus,
  getAllStoreNames,
  preprocessStoreList,
} from "../tools/helpers";

const CONTENT_WIDTH = 900;

const Styles = {
  container: css`
    max-width: ${CONTENT_WIDTH}px;
    margin: 0px auto;
  `,
  navigation: css`
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    max-width: ${CONTENT_WIDTH}px;
    height: 50px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
  `,
  userIdInput: css`
    padding: 8px;
    flex: 8;
    color: #555;
    font-size: 14px;
  `,
  fetchButton: css`
    color: #fff;
    flex: 2;
    line-height: 50px;
    text-align: center;
    background: #131732;
    letter-spacing: 0.5px;
    font-size: 12px;
  `,
  loginButton: css`
    color: #fff;
    flex: 2;
    line-height: 50px;
    text-align: center;
    background: #131732;
    letter-spacing: 0.5px;
    font-size: 12px;
  `,
  greeting: css`
    margin: 20px 0;
    font-size: 25px;
    font-weight: 700;
  `,
  content: css`
    padding-top: 50px;
  `,
  message: css`
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
  `,
};

export const Layout: React.FC<{
  auth: Auth;
  tenant: string;
  children: React.ReactNode;
  onFetchShoes: (userID: string) => void;
  onFetchStoreNames: (storeNames: string[]) => void;
  error: string;
  uiConfig: UiConfig;
}> = ({
  auth,
  children,
  tenant,
  onFetchShoes,
  onFetchStoreNames,
  error,
  uiConfig,
}) => {
  const [userId, setUserId] = React.useState("");
  const userIdInputRef = React.useRef(null);
  const loggedIn = auth && auth.isAuthenticated();

  React.useEffect(() => {
    const activeUserId = sessionStorage.getItem("activeUserId");
    if (activeUserId && loggedIn) {
      setUserId(activeUserId);
      onFetchShoes(activeUserId);
    }
  }, []);

  // fetch store list once being authenticated
  React.useEffect(() => {
    if (loggedIn) {
      // send mixpanel event
      sendAnalyticsEvent("Login successfully", { tenant });

      if (uiConfig.enable_store_filter) {
        getStoreList(tenant)
          .then(checkStatus)
          .then((res) => res.json())
          .then((res) => res.data)
          .then((data) => {
            console.log(data);
            // filter out items without pos ids, sort items by org names
            data = preprocessStoreList(data);
            console.log(data);

            const storeNames = getAllStoreNames(data);
            console.log(storeNames);

            onFetchStoreNames(storeNames);
            sessionStorage.setItem(
              `${tenant}_storeNames`,
              JSON.stringify(storeNames)
            );
            sessionStorage.setItem(`${tenant}_storeList`, JSON.stringify(data));
          });
      }
    }
  }, [loggedIn]);

  const login = () => {
    localStorage.setItem("user_url", window.location.pathname);
    auth.login();
  };
  const fetchShoes = (userId: string) => {
    sessionStorage.setItem("activeUserId", userId);
    onFetchShoes(userId);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.navigation}>
        {loggedIn && (
          <>
            <input
              className={Styles.userIdInput}
              type={uiConfig.user_id_type === "partner_id" ? "text" : "email"}
              placeholder={
                uiConfig.user_id_type === "partner_id"
                  ? "Enter partner ID"
                  : "Enter your email"
              }
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              ref={userIdInputRef}
            />
            <a
              className={Styles.fetchButton}
              onClick={() => fetchShoes(userId)}
            >
              Fetch
            </a>
          </>
        )}
        <a
          className={Styles.loginButton}
          onClick={loggedIn ? auth.logout : login}
        >
          {loggedIn ? "Logout" : "Login"}
        </a>
      </div>
      <div className={Styles.content}>
        {loggedIn && children}
        {!loggedIn && <div className={Styles.message}>You're logged out</div>}
      </div>
      {loggedIn && <ErrorHandler err={error} tenant={tenant} />}
    </div>
  );
};

export const ErrorHandler: React.FC<{
  err: any;
  tenant: string;
}> = ({ err, tenant }) => {
  let msg = "";
  const userId = sessionStorage.getItem("activeUserId");
  if (!err) return null;
  if (err.reason === "invalid email") {
    msg = "UserId invalid";
  } else if (err.status === 404) {
    msg = `No data available for ${userId}`;
  } else if (err.message == "data.shoes is null") {
    msg = "There are no shoes matching search criteria";
  } else {
    msg = "Something went wrong";
  }
  // send mixpanel event
  sendAnalyticsEvent("User saw error", {
    active_email: userId,
    error_message: msg,
    tenant,
  });
  return <div className={Styles.message}>{msg}</div>;
};
