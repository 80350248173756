const GATEWAY_BASE_ECOM_URL = "https://api.volumental.com/v1";

const GATEWAY_ALPHA_BASE_ECOM_URL = "https://api.volumental.com/v1alpha";

export const createShoeWall = async (
  tenant: string,
  userID: string,
  includeFitZones: boolean,
  includeImages: boolean,
  count: number,
  posid: string,
  category: string
) => {
  const accessToken = localStorage.getItem("access_token");
  // TODO: set posID to 1 if tenant is volumental.
  const posidParam = tenant === "volumental" ? "1" : posid;
  var queryString = `fitzones=${includeFitZones}&images=${includeImages}&count=${count}&posid=${posidParam}`
  if (category != "") {
    queryString += `&category=${category}`;
  }
  const URL =
    GATEWAY_BASE_ECOM_URL +
    `/${tenant}/users/${userID}/recommendations/shoewall?${queryString}`;
  const options = {
    method: "POST",
    headers: {
      "User-Agent": "client",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return await fetch(URL, options);
};

export const getStoreList = async (tenant: string) => {
  const accessToken = localStorage.getItem("access_token");
  const URL = GATEWAY_ALPHA_BASE_ECOM_URL + `/${tenant}/stores`;
  const options = {
    method: "GET",
    headers: {
      "User-Agent": "client",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return await fetch(URL, options);
};


const DUMMY_PROFILE = {
  profile: {
    id: "TAnUg3emWNJh_P7IJYkojA==",
    email: "sc_starfish@comcast.net",
    email_hash: "4c09d48377a658d261fcfec82589288c",
    loyalty_account_number: "",
    persons: [
      {
        name: "John Lares",
        date_of_birth: "0001-01-01T00:00:00Z",
        gender_age: "male",
      },
    ],
    date: "2019-11-29T22:34:58.635926Z",
    last_modified: "2019-11-29T22:34:58.635927Z",
  },
  store_visits: [
    {
      id: "WkRgXgtCdKOojB37UoTd0Q==",
      date: "2020-05-05T11:14:38.618Z",
      profile_id: "TAnUg3emWNJh_P7IJYkojA==",
      location: "N/A",
      person: "John Lares",
      notes: '{"interests":",lifestyle","weekly":"N/A","surface":",other"}',
      consent: "true",
      scans: [{ external_link: "affbde5b-9016-4eb1-95fd-a6d4f7817b70" }],
      last_modified: "2020-05-05T11:14:39.345461Z",
      device_id: "vandra-600",
      transactions: [
        {
          Amount: 150,
          EmailHash: "4c09d48377a658d261fcfec82589288c",
          SKU: "1019271-DTGO",
          Store: "259532",
          TransactionDate: "2019-11-29T18:21:40.523Z",
          SalePrice: 150,
          RetailPrice: 150,
          Type: "purchase",
          UPC: "192410288885",
          Quantity: 1,
          ReturnDescription: "",
        },
        {
          Amount: 50,
          EmailHash: "4c09d48377a658d261fcfec82589288c",
          SKU: "1000-NVY",
          Store: "259532",
          TransactionDate: "2019-11-29T18:21:40.523Z",
          SalePrice: 50,
          RetailPrice: 50,
          Type: "purchase",
          UPC: "848282001069",
          Quantity: 1,
          ReturnDescription: "",
        },
        {
          Amount: 125,
          EmailHash: "4c09d48377a658d261fcfec82589288c",
          SKU: "M880-2E-CH9",
          Store: "259532",
          TransactionDate: "2019-11-29T18:21:40.523Z",
          SalePrice: 125,
          RetailPrice: 125,
          Type: "purchase",
          UPC: "193362387800",
          Quantity: 1,
          ReturnDescription: "",
        },
      ],
    },
    {
      id: "---3qlN-2n4OuhKqYJwj7Q==",
      date: "2019-11-29T22:35:38.75Z",
      profile_id: "TAnUg3emWNJh_P7IJYkojA==",
      location: "N/A",
      person: "John Lares",
      notes: '{"interests":",lifestyle","weekly":"N/A","surface":",other"}',
      consent: "true",
      scans: [{ external_link: "affbde5b-9016-4eb1-95fd-a6d4f7817b70" }],
      last_modified: "2019-11-29T22:35:39.10149Z",
      device_id: "vandra-600",
      transactions: [
        {
          Amount: 150,
          EmailHash: "4c09d48377a658d261fcfec82589288c",
          SKU: "1019271-DTGO",
          Store: "259532",
          TransactionDate: "2019-11-29T18:21:40.523Z",
          SalePrice: 150,
          RetailPrice: 150,
          Type: "purchase",
          UPC: "192410288885",
          Quantity: 1,
          ReturnDescription: "",
        },
        {
          Amount: 50,
          EmailHash: "4c09d48377a658d261fcfec82589288c",
          SKU: "1000-NVY",
          Store: "259532",
          TransactionDate: "2019-11-29T18:21:40.523Z",
          SalePrice: 50,
          RetailPrice: 50,
          Type: "purchase",
          UPC: "848282001069",
          Quantity: 1,
          ReturnDescription: "",
        },
        {
          Amount: 125,
          EmailHash: "4c09d48377a658d261fcfec82589288c",
          SKU: "M880-2E-CH9",
          Store: "259532",
          TransactionDate: "2019-11-29T18:21:40.523Z",
          SalePrice: 125,
          RetailPrice: 125,
          Type: "purchase",
          UPC: "193362387800",
          Quantity: 1,
          ReturnDescription: "",
        },
      ],
    },
    {
      id: "zdnO61OlGlqnEzws8HbG3w==",
      date: "2019-11-29T22:34:58.229Z",
      profile_id: "TAnUg3emWNJh_P7IJYkojA==",
      location: "N/A",
      person: "John Lares",
      notes: '{"interests":",lifestyle","weekly":"N/A","surface":",other"}',
      consent: "true",
      scans: null,
      last_modified: "2019-11-29T22:34:59.092911Z",
      device_id: "vandra-600",
      transactions: null,
    },
  ],
};

export const getProfileData = async (tenant, email) => {
  // const accessToken = localStorage.getItem('access_token');
  // const URL = `https://api.volumental.com/v1alpha/${tenant}/profiles/${email}`;
  // const options = {
  //   method: 'GET',
  //   headers: {
  //     'User-Agent': 'client',
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${accessToken}`,
  //   },
  // };
  return DUMMY_PROFILE;
};
