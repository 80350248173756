
type UserIdType = 'email_hash' | 'partner_id';

interface UiConfigMap {
  [key: string]: UiConfig;
}

export interface UiConfig {
  enable_store_filter:          boolean;
  category_list:                string[];
  user_id_type:                 UserIdType;
  enable_whole_catalog_search:  boolean;
}

const taf: UiConfig = {
  enable_store_filter:          true,
  category_list:                ['all', 'run', 'walk', 'school'],
  user_id_type:                 'partner_id',
  enable_whole_catalog_search:  false,
}

const road_runner: UiConfig = {
  enable_store_filter:          true,
  category_list:                ['all', 'run', 'walk', 'school'],
  user_id_type:                 'email_hash',
  enable_whole_catalog_search:  true,
}

const fleetfeet: UiConfig = {
  enable_store_filter:          true,
  category_list:                ['all', 'neutral', 'trail', 'stability'],
  user_id_type:                 'email_hash',
  enable_whole_catalog_search:  true,
}

const volumental: UiConfig = {
  enable_store_filter:          false,
  category_list:                ['all', 'neutral', 'trail', 'stability'],
  user_id_type:                 'email_hash',
  enable_whole_catalog_search:  false,
}

const TAF: UiConfigMap = {
  taf,
  taf_au: { ...taf },
  taf_nz: { ...taf },
}

const FLEETFEET: UiConfigMap = {
  fleetfeet,
}

const ROADRUNNER: UiConfigMap = {
  road_runner,
}

const VOLUMENTAL: UiConfigMap = {
  volumental,
}

export const allUiConfigs = {
  ...TAF,
  ...FLEETFEET,
  ...ROADRUNNER,
  ...VOLUMENTAL,
}
