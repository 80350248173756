import * as React from 'react';
import { Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { sendAnalyticsReset } from '../tools/analytics';
import { Auth } from '../tools/auth';
import history from '../tools/history';
import { Home } from './HomePage';

const auth = new Auth();
sendAnalyticsReset();

type RoutePropsWithoutArgs = RouteComponentProps<{}, {}, {}>;

const handleAuthentication = (nextState: RoutePropsWithoutArgs) => {
  setTimeout(() => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      auth.handleAuthentication();
    }
  }, 200);
};

export const MainRoutes: React.SFC<{}> = () => (
  <Router history={ history }>
    <div>
      <Switch>
        {/* <Route
          path="/callback"
          render={(props) => {
            console.log('handling auth')
            return null;
          }}
        /> */}
        <Route
          path="/"
          render={(props: RoutePropsWithoutArgs) => {
            console.log('before handle auth');
            handleAuthentication(props);
            return <Home
              auth={auth}
            />
          }}
        />
      </Switch>
    </div>
  </Router>
);
